import styled from "styled-components";
import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from 'gatsby'

// in-line css
import { GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'


// Styled Components
import { ActionButton } from './ActionButton'
import { ContextExclusionPlugin } from "webpack";




// I am getting a property children is missing in type
// https://stackoverflow.com/questions/60840693/property-children-is-missing-in-type
// https://stackoverflow.com/questions/64596282/styled-components-in-a-hoc-react-component
// React.FC defines children and you state manually that you are passing the className

// data will include the image query as well as my own personal data

export const imageQuery = graphql`
  query {
    bannerImage: file(relativePath: { eq: "courses/promo-2021-07-04/blur-computer-code.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED,
            layout: FIXED,
            width: 1280
            )
      }
    }

  }
`


// Styles for this component
const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;


const composedStyle = css`
    ${tailwindStyle};
`;




const UnstyledCoursePromo: React.FC<{promo_data: any, className?: string}> = ({ promo_data, className, children }) => {
    // console.log("data in promo", promo_data)
    // double check name from graphql
    

    // https://github.com/codericky/GatsbyImageWithGraphql/blob/master/src/components/image.js
    const query_data = useStaticQuery(imageQuery)
    // console.log("query data", query_data)

    const image = getImage(query_data.bannerImage)
    // console.log("image is ", image)



    return(
        <>
            <div className={composedStyle}>
                <GlobalStyles />
                <div id="banner"
                    className={className}
                >
                    <div className="banner__main">
                            {/* <img src="/images/courses/promo-2021-07-04/blur-computer-code.jpg" /> */}
                            <div className="banner__inner">
                                <div className="headline"><h1>{promo_data.headline}</h1></div>
                                <div className="subheadline"><h3>{promo_data.subheadline}</h3></div>
                                <ActionButton
                                    as="a"
                                    href={promo_data.button.href}
                                    className="ml-mastery-button"
                                >
                                    {promo_data.button.text}
                                </ActionButton>
                            </div>

                    </div>
                </div>
            </div>
        </>
    )
}



export const LargeCoursePromo = styled(UnstyledCoursePromo)`
    display: flex;

    .banner__main {
        background: url('/images/courses/promo-2021-07-04/blur-computer-code.jpg') no-repeat center fixed;
        background-size: cover;
        aspect-ratio: auto;
        height: 100%;
        padding: 0 15vw;
        position: relative;
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
        /* top: -2vw; */
        
    }

    @media (max-width: 720px){
        .banner__main {
            padding: unset !important;
            width: unset !important;
        }
    }

    /* .banner__main img{
        width: 100vw;
        margin-left: calc(40% - 40vw);
        padding: 0 0; 
        top: -4vw;
        position: relative;
    }  */

    /* .banner__main img{
        aspect-ratio: auto;
        width: 100%;
        padding: 0 0; 
        top: -4vw;
        position: absolute;
        left: 0;
    }  */



    .banner__inner {
        text-align: center;
        
    }
    
    .headline h1 {
        color: white;
        margin-top: 10vh;
        z-index: 1;
    }

    .subheadline h3 {
        color: white;
        margin: 3vh 0;
    }

    .ml-mastery-button {
        margin-top: 4vh;
        margin-bottom: 10vh;
    }

`;

// TODO - Define the prop types 
// TODO - move comupter code jpg to right directory and change the refrerence




