import styled from "styled-components";


// todo - transistion is not yet working

// I am getting a property children is missing in type
// https://stackoverflow.com/questions/60840693/property-children-is-missing-in-type
// https://stackoverflow.com/questions/64596282/styled-components-in-a-hoc-react-component
// React.FC defines children and you state manually that you are passing the className

const DivButton: React.FC<{className?: string}> = ({ className, children }) => (
    <div className={className}>
        {children}
    </div>
);


export const ActionButton = styled(DivButton)`


    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
    
    color: white; 
    background-color: hsla(354, 98%, 50%, 1);

    border-radius: 44px;
    font-size: 20px;

    margin-top: 0px;
    margin-left: 10px;
    padding: 12px 40px;
    align-self: center;

    -webkit-transition: background-color .4s,opacity .4s,color .4s;
    transition: background-color .4s, opacity .4s, color .4s;
    
    :hover {
        color: white;
        background-color: hsla(354, 98%, 25%, 1);
        /* background-color: gray; */
        text-decoration: none;
    }

`;