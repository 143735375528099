import React from 'react'
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby'

import '../../../styles/typography.css'

// in-line css
import { theme, GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'



// TODO - SOMETHING IN TEACHABLE NEEDS TO STATE THIS IS THE CANONICAL

// const mlmastery = {"courses":
//     {"course_title": "Machine Learning Mastery",
//     "sections" : [
//             {
//                 "section_title" : "General Overview and Setup",
//                 "lessons": [
//                     {"lesson_title": "General Overview",
//                     "lesson_href" : "https://courses.whiteowleducation.com/courses/machine-learning-mastery/lectures/30537410",
//                     "button_class": "preview",
//                     "button_text" : "Show Video",
//                     "icon_text": "Video Icon",
//                     "icon_id": "video_icon"
//                     },
//                     {"lesson_title": "Installing Linux on Windows",
//                     "lesson_href" : "https://courses.whiteowleducation.com/courses/machine-learning-mastery/lectures/30614920",
//                     "button_class": "preview",
//                     "button_text" : "Show Video",
//                     "icon_text": "Video Icon",
//                     "icon_id": "video_icon"
//                 },
//                 ]
//             },
//             {
//                 "section_title" : "Streaming Real-Time Data With Docker",
//                 "lessons": [
//                     {"lesson_title": "(COMING SOON!) Tweet Filter Overview",
//                     "lesson_href" : "https://courses.whiteowleducation.com/courses/machine-learning-mastery/lectures/30667235",
//                     "button_class": "start",
//                     "button_text" : "Enroll",
//                     "icon_text": "Text Icon",
//                     "icon_id": "text_icon"
//                     },
//                     {"lesson_title": "(COMING SOON!) Using requests to stream data",
//                     "lesson_href" : "https://courses.whiteowleducation.com/courses/machine-learning-mastery/lectures/30667752",
//                     "button_class": "start",
//                     "button_text" : "Enroll",
//                     "icon_text": "Text Icon",
//                     "icon_id": "text_icon"
//                 },
//                 ]
//             },
//     ]
//     }

// }

// End result needs to be in the graphQL format

// const courses = {"General Overview and Setup" : "General Overview"}
// const button_href = "https://courses.whiteowleducation.com/courses/machine-learning-mastery/lectures/30537410"


function getIcon(altIconWithUnderscores?: string){
    const icons = {"video_icon": "/images/tableofcontents/youtube-brands.svg",
                   "text_icon": "/images/tableofcontents/file-icon.svg"
    }
    // console.log("GET ICON INPUT",  altIconWithUnderscores)
    if (altIconWithUnderscores) {
        const regexp = new RegExp('video')
        const isVideo = regexp.test(altIconWithUnderscores.toLowerCase());

        if (isVideo) {
            return icons["video_icon"]
        } else {
            // if this is not video, return the text icon
            return icons["text_icon"]
        }

    } else {
        // by default return the text icon 
        return icons["text_icon"]
    }

}

function capitalizeAllWords(mySentence: string){
    // https://www.freecodecamp.org/news/how-to-capitalize-words-in-javascript/
    const words = mySentence.split(" ");

    const result = words.map((word) => { 
        return word[0].toUpperCase() + word.substring(1); 
        }).join(" ");

    return result
}

function underscoreBetweenWords(mySentence: string){
    const words = mySentence.split(" ");
    const result = words.join("_")
    return result
}


const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;



const composedStyle = css`
    ${tailwindStyle};
`;


const UnstyledTOC: React.FC<{ className?: string}> = ({ className, children,  }) => {

   
    // TODO - Table of contents needs to be GENERIC
    const CourseQuery = graphql`
    query {
        gateway {
            course(_id: 0) {
                course_price,
                course_title,
                sections {
                  section_title
                  lessons {
                    lesson_title,
                    lesson_duration,
                    lesson_href,
                    button_class,
                    icon_id
                  }
                }
            }
        }
    }
    `

    const CourseData = useStaticQuery(CourseQuery)
    // console.log("TOC Course Data", CourseData)

    return (

        <div className={composedStyle}>
            <GlobalStyles />
            <div className="tableofcontents" tw="container flex lg:self-center">
                <div
                tw="flex-1"
                className={className}
                data-expanded="false"
                >
                    {children}
                    {CourseData.gateway.course.sections.map( (section) => {
                        return (
                        <div className="block__curriculum">
                            <div className="block__curriculum__section">
                                <div 
                                    tw="text-base mt-8 "
                                    className="block__curriculum__section__title">
                                        {section.section_title}
                                </div>
                                    {/* Iterate through the lessons  */}
                                    {section.lessons.map( (lesson) => {
                                        // call helper functions :)    
                                        const iconAltText = capitalizeAllWords(lesson.icon_id)
                                        const iconIDWithUnderScore = underscoreBetweenWords(lesson.icon_id)
                                        const buttonNameLookup = {"preview": "SHOW VIDEO", "start": "ENROLL"}

                                        return (
                                            <ul className="block_curriculum__section__list">
                                                <li className="block__curriculum__section__list__item">
                                                    <a className="block__curriculum__section__list__item__link lesson"
                                                    href={'https://courses.whiteowleducation.com' + lesson.lesson_href}
                                                    >
                                                        <span className="block__curriculum__section__list__item__lecture-icon">
                                                            <img alt={iconAltText} 
                                                                className="block__curriculum__section__list__item__lecture-icon__svg"
                                                                src={getIcon(iconIDWithUnderScore)}
                                                            />

                                                        </span>
                                                        <span  tw="text-xs md:text-base text-elegant-black">{lesson.lesson_title} {lesson.lesson_duration}</span>
                                                        <button className={`block__curriculum__section__list__item__button ${lesson.button_class}` }>
                                                            {buttonNameLookup[lesson.button_class]}
                                                        </button>
                                                    </a>
                                                </li>
                                            </ul>
                                        )
                                    }) 
                                }
                            </div>
                        </div>
                        );
                    })}


                </div>
            </div>
        </div>
    );
}

const brand_secondary = "#09A59A";
const brand_text = "#2b3636"


export const TOC = styled(UnstyledTOC)`
    /* display: flex; */

    .block__curriculum {
        font-family: sans-serif;
        font-size: 80%;
        
    }

    .block__curriculum__section__title {
        background-color: #EFEFF5;
        border-bottom: 1px solid #fff;
        font-weight: 700;
        padding: 10px 16px;
    }

    .block__curriculum__section__list ol, ul {
        list-style: none;
        margin: 0;
    }

    .block__curriculum__section__list__item {
        background-color: #F9FAFD;
        
        border-bottom: 1px solid #fff;
        -webkit-transition: background-color 0.2s ease-out;
        transition: background-color 0.2s ease-out;
    }

    .block__curriculum__section__list__item :hover {
        background-color: #cdcdce;
    }

    .block__curriculum__section__list__item__link {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 11px 16px;
        position: relative;
        width: 100%;
    }

    a {
        text-decoration: none;
        color: ${brand_secondary};
    }

    .block__curriculum__section__list__item__lecture-icon {
        display: inline-block;
        height: 16px;
        margin-right: 10px;
        min-width: 16px;
        width: 16px;
    }

    .block__curriculum__section__list__item__lecture-icon__svg {
        display: block;
        max-height: 100%;
        max-width: 100%; 
    }

    .block__curriculum__section__list__item__lecture-name {
        color: ${brand_text};
        font-size: .9rem;
    }

    .block__curriculum__section__list__item__button {
        background-color: ${brand_secondary};
        border: 1px solid ${brand_secondary};
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-size: .8rem;
        font-weight: 700;
        margin-left: auto;
        padding: 5px 10px;
        text-transform: uppercase;
    }

    .block__curriculum .start {
        opacity: 0.5;
    }
`;