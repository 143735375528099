import React from 'react'
// import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby'
import { Layout } from '../../components/common'
import { LargeCoursePromo } from '../../components/styledcomponents/LargeCoursePromo'
import { TOC } from '../../components/styledcomponents/courses/TableOfContents'

import '../../styles/typography.css'

// in-line css
import { GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'


/*
data: This is the data frome the GraphQL query
*/

const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const TOCStyle = css`
    h1 {
        font-size: 2.8rem;
        font-weight: 700;
        font-family: "NewsGothic";
    }

    h3 {
        font-size: 1.15rem;
        font-weight: 500;
    }
`;

const composedStyle = css`
    ${tailwindStyle};
    ${TOCStyle};
`;

// ML Mastery uses the zero index
const MLMasteryPage = ({data}) => {
    const priceQuery = graphql`
    query {
        gateway {
            course(_id: 0) {
                course_price
            }
        }
    }
    `

    const price_data = useStaticQuery(priceQuery)

    const button_text = `ENROLL NOW FOR \$${price_data.gateway.course.course_price}!`


    const largeCoursePromoData = {
        "headline": "The Ultimate Machine Learning Course",
        "subheadline": "Don't get left behind on the current trends in Artificial Intelligence.",
        "button" : {
            "href": "https://whiteowleducation.teachable.com/purchase?product_id=2847654",
            "text": button_text
        }
    
    }

    const siteName = "White Owl Education"
    const title = "Machine Learning Mastery"
    const description = "Make AI work for you. Stream data and deploy models with 10 minutes of studying per day."
    const cardImage = "https://whiteowleducation.com/software-box-social.png"

    // Does it work without URL

    return (
    <Layout
        twitterInfo={{"title": `${title}`, 
                      "card": "summary_large_image",
                      "description": `${description}`,
                      "imgsrc": `${cardImage}`,
                      "site": "@whiteowled"
                    }}
        fbInfo={{
                "image": `${cardImage}`,
                "description": `${description}`,
                "title": `${title}`,
                "type": "website",
                "site_name": `${siteName}`
        }}
    >
        <div className={composedStyle}>
            <GlobalStyles />
            <div className="container">
                <LargeCoursePromo
                    promo_data = {largeCoursePromoData}
                />
                <article className="content" style={{ textAlign: `center` }}>
                    <h1 tw="mb-8" className="content-title">Machine Learning Mastery</h1>
                    <section className="content-body">
                        <TOC />
                    </section>
                </article>
            </div>
        </div>
    </Layout>
)
}


// const MLMasteryPageQuery = props => (
//     <StaticQuery
//         query={graphql`
//             query MLMasteryQuery {
//                 courses {
//                     button
//                 }
//             }
//         `}
//         render={data => <MLMasteryPage data={data} {...props} />}
//     />
// )


// export default MLMasteryPageQuery


export default MLMasteryPage
